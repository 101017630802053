






































































































import Vue from 'vue';
import * as Api from '@/api/dictionaryList';
import moment from 'moment';
const columns = [
  {
    title: '字典编码',
    dataIndex: 'dictCode',
    key: 'dictCode',
    align: 'center',
    scopedSlots: { customRender: 'dictCode' },
  },
  {
    title: '字典名称',
    dataIndex: 'dictName',
    key: 'dictName',
    align: 'center',
  },
  {
    title: '备注',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    width: '240px',
    ellipsis: true,
  },
  {
    title: '创建时间',
    dataIndex: 'recDate',
    key: 'recDate',
    align: 'center',
    scopedSlots: { customRender: 'recDate' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
const dataSource = [];
export default Vue.extend({
  name: 'dictList',
  data() {
    return {
      tableLoading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      formState: {
        dictName: '',
        dictCode: '',
      },
      dataSource,
      columns,
      formVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      loading: false,
      type: false,
      form: {
        dictName: '',
        dictCode: '',
        description: '',
      },
      rules: {
        dictName: [
          {
            required: true,
            message: '请输入字典名称',
            trigger: 'change',
          },
        ],
        dictCode: [
          {
            required: true,
            message: '请输入字典编码',
            trigger: 'change',
          },
        ],
      },
      deleteVisible: false,
      deleteId: 0,
    };
  },
  created() {
    this.handleSubmit();
  },
  methods: {
    moment,
    // 查询按钮
    handleSubmit() {
      this.pagination.current = 1;
      this.getDictList();
    },
    getDictList() {
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        dictCode: this.formState.dictCode,
        dictName: this.formState.dictName,
      };
      this.tableLoading = true;
      Api.searchDict(param)
        .then((res) => {
          const data = res;
          if (data.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    reset() {
      this.formState.dictCode = '';
      this.formState.dictName = '';
      this.handleSubmit();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDictList();
    },
    // 新增字典目录
    newForm(record) {
      this.formVisible = true;
      if (record === 0) {
        this.type = false;
        this.form.description = '';
        this.form.dictCode = '';
        this.form.dictName = '';
      } else {
        this.form = record;
        this.type = true;
      }
    },
    // 字典目录表单提交
    onSubmit() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          if (this.type === false) {
            Api.saveDict(this.form)
              .then((res) => {
                if (res) {
                  this.loading = true;
                  this.handleSubmit();
                  this.formVisible = false;
                }
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            const params = {
              sequenceNbr: this.form['sequenceNbr'],
              description: this.form.description,
              dictCode: this.form.dictCode,
              dictName: this.form.dictName,
            };
            Api.editDict(params)
              .then((res) => {
                if (res) {
                  this.loading = true;
                  this.handleSubmit();
                  this.formVisible = false;
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    cancel() {
      this.formVisible = false;
      this.handleSubmit();
    },
    // 删除字典目录
    deleteDict(record) {
      this.deleteVisible = true;
      this.deleteId = record.sequenceNbr;
    },
    deleteConfirm() {
      Api.deleteDict([this.deleteId])
        .then((res) => {
          if (res) {
            this.loading = true;
            this.deleteVisible = false;
            this.handleSubmit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 字典名称操作按钮
    check(record) {
      this.$router.push({
        path: '/platform/dictionaryList/valueList',
        query: {
          id: record.dictCode,
        },
      });
    },
  },
});
